import GlobalAppBar, {
  MobileHamburgurMenuView,
} from '@components/GlobalAppBar';

import { useGlobalLoadingLayerProps } from '@components/GlobalLoadingLayer';
import { useGlobalMessageBannerProps } from '@components/GlobalMessageBanner';
import LoadingLayer from '@components/LoadingLayer';
import MessageBanner from '@components/MessageBanner';
import SessionExtendFloatingButton from '@components/SessionExtendFloatingButton';
import LoadingPage from '@pages/Loading';
import { getCursorStyle } from '@utils';

import mobileDrawerOpenedState from '../../mobile-detector/recoil/atom/mobileDrawerState';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import Footer from '../../legacy/components/Footer';
import { withMobileDetector } from '../../mobile-detector';
import { useGlobalSubHeaderProps } from '../GlobalSubHeader';
import SubHeader from '../SubHeader';
import { useChannelTalk } from './hooks';
import styles from './index.module.scss';
import { useAppSelector } from '@store/hooks';
import { selectUser } from '@store/slices';
import {
  BUILD_USAGE,
  STORAGE_NAME_BANNER,
  SOCAR_MARKETING_URL,
  ONLINE_MARKETING_URL,
  LOCALSCHOOLS
} from '@constants';
import { KDTBanner } from '@components/GlobalAppBar/KDTBanner';
import { ONLINEBanner } from '@components/GlobalAppBar/ONLINEBanner';
import classNames from 'classnames';
import { useCurrentCampusId } from '@utils/validateToken';

const AppLayout: React.FC = ({ children }) => {
  const currentLocalSchoolId = useCurrentCampusId();

  useChannelTalk();

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const globalSubHeaderProps = useGlobalSubHeaderProps();
  const globalMessageBannerProps = useGlobalMessageBannerProps();
  const globalLoadingLayerProps = useGlobalLoadingLayerProps();

  const user = useAppSelector(selectUser);
  const isMobileDrawerOpend = useRecoilValue(mobileDrawerOpenedState);

  const isBannerHidden = useMemo(() => {
    return localStorage.getItem(STORAGE_NAME_BANNER) === 'true';
  }, [localStorage]);

  const [showBanner, setShowBanner] = useState<boolean>(!isBannerHidden);

  const marginTopCss = useMemo(() => {
    if (BUILD_USAGE === 'kdc') {
      return 'mt-[48px]';
    } else {
      return '!mt-0';
    }
  }, []);

  const hideBannerUsingStorage = (e: any) => {
    e.preventDefault();
    localStorage.setItem(STORAGE_NAME_BANNER, 'true');
    setShowBanner(false);
  };

  if (isLoggingOut) {
    return (
      <LoadingPage
        message={'실행 중인 컨테이너를 찾아 종료하는 중입니다... 👀'}
      />
    );
  }


  return (
    <div className={styles.layout} style={getCursorStyle()}>
      <GlobalAppBar setIsLoggingOut={setIsLoggingOut} />
      {BUILD_USAGE === 'kdc' && showBanner && (
        <div
          className={
            'absolute top-[88px] left-0 right-0 w-full md:relative md:top-0'
          }
        >
          <KDTBanner
            link={SOCAR_MARKETING_URL}
            onClickHideBanner={hideBannerUsingStorage}
          />
        </div>
      )}

      {/* 한화(27)일 경우에만 배너 표시 : /src/constants/localSchools/index.ts */}
      {BUILD_USAGE === 'online' && showBanner && currentLocalSchoolId === 27 && (
        <div
          className={
            'absolute top-[88px] left-0 right-0 w-full md:relative md:top-0'
          }
        >
          <ONLINEBanner
            link={ONLINE_MARKETING_URL}
            onClickHideBanner={hideBannerUsingStorage}
          />
        </div>
      )}
      {globalMessageBannerProps && (
        <MessageBanner {...globalMessageBannerProps} />
      )}

      {globalSubHeaderProps && <SubHeader {...globalSubHeaderProps} />}
      <div className={classNames(styles.content, marginTopCss)}>{children}</div>
      <Footer />
      <SessionExtendFloatingButton
        className={styles.sessionExtendFloatingButton}
      />
      {globalLoadingLayerProps && <LoadingLayer {...globalLoadingLayerProps} />}
      {isMobileDrawerOpend && <MobileHamburgurMenuView user={user} />}
    </div>
  );
};

export default withMobileDetector(AppLayout);
